import {
    SiDiscord,
    SiMastodon,
    SiMatrix,
    SiX,
} from '@icons-pack/react-simple-icons';
import Layout from 'components/Layout';
import ExternalLink from 'components/links/ExternalLink';
import { PageProps } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
    discordDirectURL,
    mastodonURL,
    matrixDirectURL,
    twitterURL,
} from 'components/footer-social-media';

const Community: React.FC<PageProps> = ({ path }) => {
    const layoutProps = {
        path,
        title: 'Ente - Community',
        description: 'We are wherever you are, come hang out!',
    };

    return (
        <Layout {...layoutProps}>
            <Body>
                <Section>
                    <Content />
                </Section>
            </Body>
        </Layout>
    );
};

export default Community;

const Body: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="max-w-lg mx-auto d-flex flex-column my-5">
            {children}
        </div>
    );
};

const Section: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <section className="bg-elevated rounded-3 px-3 py-3 px-md-5 text-break">
            {children}
        </section>
    );
};

const Content: React.FC = () => {
    return (
        <div className="d-md-flex flex-wrap align-items-center gap-5">
            <ColumnItem>
                <ColumnOne />
            </ColumnItem>
            <ColumnItem>
                <ColumnTwo />
            </ColumnItem>
        </div>
    );
};

const ColumnItem = styled.div`
    flex: 1;
    margin: 1.5rem auto;
`;

const ColumnOne: React.FC = () => {
    return (
        <div className="d-flex flex-row gap-3">
            <div>
                <h2 className="mt-0">Our community</h2>
                <Description />
            </div>
        </div>
    );
};

const Description: React.FC = () => {
    return (
        <>
            <p>
                We build Ente in the open, with continuous community feedback.
                Our{' '}
                <ExternalLink href="https://github.com/ente-io">
                    source code
                </ExternalLink>{' '}
                is open, our{' '}
                <ExternalLink href="https://www.figma.com/file/SYtMyLBs5SAOkTbfMMzhqt/ente-Visual-Design">
                    Figma designs
                </ExternalLink>{' '}
                are open, our{' '}
                <ExternalLink href="/discord">discussions</ExternalLink> are
                open.
            </p>
            <p>
                Having this journey with y'all is not just our secret sauce – it
                is just much more fun this way!{' '}
                <CTAText>So come, join us.</CTAText>
            </p>
        </>
    );
};

const CTAText = styled.span`
    font-weight: 500;
`;

const ColumnTwo: React.FC = () => {
    return <CommunityPresences />;
};

const CommunityPresences: React.FC = () => {
    return (
        <div className="d-flex justify-content-between align-items-end px-md-4">
            <TwitterLink />
            <MastodonLink />
            <MatrixLink />
            <DiscordLink />
        </div>
    );
};

const CommunityLink = styled(ExternalLink)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-variant: all-petite-caps;
    font-weight: 500;
    padding-top: 1.5rem;

    color: var(--color-primary-500);
    && svg {
        opacity: 1;
    }

    &&:hover {
        color: var(--color-primary-700);
    }

    @media (prefers-color-scheme: dark) {
        &&:hover {
            color: var(--color-primary-400);
        }
    }
`;

const communityLinkIconSize = 52;

const TwitterLink: React.FC = () => {
    return (
        <CommunityLink href={twitterURL} title="X (Formerly Twitter)">
            <SiX size={communityLinkIconSize} title="X (Formerly Twitter)" />
            X (Twitter)
        </CommunityLink>
    );
};

const MastodonLink: React.FC = () => {
    return (
        <CommunityLink href={mastodonURL} title="Mastodon">
            <SiMastodon size={communityLinkIconSize} title="Mastodon" />
            Mastodon
        </CommunityLink>
    );
};

const MatrixLink: React.FC = () => {
    return (
        <CommunityLink href={matrixDirectURL} title="Matrix">
            <SiMatrix size={communityLinkIconSize} title="Matrix" />
            Matrix
        </CommunityLink>
    );
};

const DiscordLink: React.FC = () => {
    return (
        <CommunityLink href={discordDirectURL} title="Discord">
            <SiDiscord size={communityLinkIconSize} title="Discord" />
            Discord
        </CommunityLink>
    );
};
